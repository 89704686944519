<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="650px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="标题" prop="title">
              <el-input v-model="form.title" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="副标题" prop="subtitle">
              <el-input v-model="form.subtitle" maxlength="60"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分类管理" prop="categoryId">
              <el-select v-model="form.categoryId" placeholder="请选择">
                <el-option v-for="item in categoryData" :key="item.id" :label="item.categoryName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="资讯封面图片" prop="coverPic">
              <el-upload
              class="upload"
              v-if="showPic"
              :show-file-list="false"
              :with-credentials="true"
              :action="apis.ImageUpload"
              :httpRequest="uploadHttpDefault"
              :headers="{
                  token: TOKEN
              }"
              name="file"
              accept=".png, .jpg"
              :limit="1"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              >
              <img class="image-upload" src="../../../../assets/images/upload_image.png" v-if="form.coverPic === ''" alt="" srcset="">
              <img v-if="form.coverPic" :src="form.coverPic" alt srcset width="50" height="50" />
              <div class="upload-images-tip">
                  图片尺寸不小于320*174px，图片格式png/jpg
              </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"><div class="course-text">资讯详情内容（图片）</div></el-col>
          <el-col :span="24">
            <el-form-item prop="detail" label-width="0">
              <div class="edit_container">
                <quill-edit :detail="form.detail" @change="onEditChange" :qnLocation="apis.ImageUpload">
                </quill-edit>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="onPreview">预览</el-button>
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <preview-image ref="previewImage" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import previewImage from "./preview.vue";
import quillEdit from '@/components/quillEdit.vue'

export default {
  mixins: [mixin],
  components: {
    quillEdit,
    previewImage,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    categoryID: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryData: [], // 分类的下拉框选项
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      showPic: true,
      editorOption: {
        placeholder: '请输入...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['image', 'formula'], //去除video即可
          ],
        },
      },
      previewImage: {
        visible: false,
        src: "",
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    /**
     * @function 获取分类的下拉框选项值
     **/
    getOptionData() {
      this.$http.get(apis.contentCategoryGetIdAndName).then((res) => {
        if (res.data.code === 0) {
          this.categoryData = res.data.data
        }
      })
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      // this.form.categoryId = this.categoryID
      // console.log("categoryID-", this.categoryID);
    },
    /**
     * @function 文本编辑器
    */
    onEditChange(value) {
      this.form.detail = value
      this.$refs.form.validateField("detail");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = { ...this.form };
          if (this.isEdit) {
            this.$http.put(apis.fitnessinstruction, formData).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog()
              }
            })
          } else {
            this.$http.post(apis.fitnessinstructionAdd, formData).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog()
              }
            })
          }
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      console.log(data)
      this.$http.get(`${apis.fitnessinstruction}/${data[0].id}`).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data)
          this.form = {
            ...res.data.data,
          };
        }
      });
    },
    // 封面图片成功
    uploadSuccess(res) {
      this.form.coverPic = res.data.url
      this.showPic = false
      this.$nextTick(() => {
        this.showPic = true
      })
      this.$refs.form.validate('coverPic');
    },
    // 封面图片/地图图标失败
    uploadError(res) {
      console.log(res);
    },
    openDialog() {
      this.getOptionData()
      setTimeout(() => {
        this.form.categoryId = this.categoryID
      }, 500);
      // console.log("categoryID-", this.categoryID);
    },
    // 预览
    onPreview() {
      this.$refs.form.validate((valida) => {
        if (valida) {
          this.previewImage.visible = true
          this.$nextTick(() => {
            this.$refs['previewImage'].getPreviewData(this.form)
          })
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
.convenienceDialog {
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .edit_container {
    .ql-snow {
      .ql-tooltip {
        left: 10px !important;
      }
    }
  }
}
</style>
